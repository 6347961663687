<template>
  <div>
    <div class="card">
      <div class="card-body">
        <!--        <div class="form-group">
          <label>Porcentaje de interés base</label>
          <input
            type="number"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <label>Porcentaje de interés moratorio base</label>
          <input
            type="number"
            class="form-control"
          >
        </div>

        <div class="form-group">
          <label>Días para cobrar interés moratorio</label>
          <input
            type="number"
            class="form-control"
          >
        </div>

        <button class="btn btn-primary">
          Guardar
        </button>-->

        <a
          :href="downloadUrl"
          class="btn btn-primary"
        >
          <feather-icon icon="DownloadIcon" />
          Descargar formato para importación de clientes
        </a>

        <button
          class="btn btn-primary"
        >
          <feather-icon icon="UploadIcon" />
          Importar clientes
        </button>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ConfigVue',
  computed: {
    downloadUrl() {
      return `${process.env.VUE_APP_API_URL}/formatos/formato_clientes.xlsx`
    },
  },
}
</script>

<style scoped>

</style>
